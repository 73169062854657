.dark .ql-snow.ql-toolbar button,
.dark .ql-snow .ql-toolbar button,
.dark .ql-snow.ql-toolbar button,
.dark .ql-snow .ql-toolbar button,
.dark .ql-snow.ql-toolbar button,
.dark .ql-snow .ql-toolbar button,
.dark .ql-snow.ql-toolbar .ql-picker-label,
.dark .ql-snow .ql-toolbar .ql-picker-label,
.dark .ql-snow.ql-toolbar .ql-picker-label,
.dark .ql-snow .ql-toolbar .ql-picker-label,
.dark .ql-snow.ql-toolbar .ql-picker-item,
.dark .ql-snow .ql-toolbar .ql-picker-item,
.dark .ql-snow.ql-toolbar .ql-picker-item,
.dark .ql-snow .ql-toolbar .ql-picker-item {
  color: #fff;
}
.dark .ql-snow.ql-toolbar button .ql-fill,
.dark .ql-snow .ql-toolbar button .ql-fill,
.dark .ql-snow.ql-toolbar button .ql-fill,
.dark .ql-snow .ql-toolbar button .ql-fill,
.dark .ql-snow.ql-toolbar button .ql-fill,
.dark .ql-snow .ql-toolbar button .ql-fill,
.dark .ql-snow.ql-toolbar .ql-picker-label .ql-fill,
.dark .ql-snow .ql-toolbar .ql-picker-label .ql-fill,
.dark .ql-snow.ql-toolbar .ql-picker-label .ql-fill,
.dark .ql-snow .ql-toolbar .ql-picker-label .ql-fill,
.dark .ql-snow.ql-toolbar .ql-picker-item .ql-fill,
.dark .ql-snow .ql-toolbar .ql-picker-item .ql-fill,
.dark .ql-snow.ql-toolbar .ql-picker-item .ql-fill,
.dark .ql-snow .ql-toolbar .ql-picker-item .ql-fill,
.dark .ql-snow.ql-toolbar button .ql-stroke.ql-fill,
.dark .ql-snow .ql-toolbar button .ql-stroke.ql-fill,
.dark .ql-snow.ql-toolbar button .ql-stroke.ql-fill,
.dark .ql-snow .ql-toolbar button .ql-stroke.ql-fill,
.dark .ql-snow.ql-toolbar button .ql-stroke.ql-fill,
.dark .ql-snow .ql-toolbar button .ql-stroke.ql-fill,
.dark .ql-snow.ql-toolbar .ql-picker-label .ql-stroke.ql-fill,
.dark .ql-snow .ql-toolbar .ql-picker-label .ql-stroke.ql-fill,
.dark .ql-snow.ql-toolbar .ql-picker-label .ql-stroke.ql-fill,
.dark .ql-snow .ql-toolbar .ql-picker-label .ql-stroke.ql-fill,
.dark .ql-snow.ql-toolbar .ql-picker-item .ql-stroke.ql-fill,
.dark .ql-snow .ql-toolbar .ql-picker-item .ql-stroke.ql-fill,
.dark .ql-snow.ql-toolbar .ql-picker-item .ql-stroke.ql-fill,
.dark .ql-snow .ql-toolbar .ql-picker-item .ql-stroke.ql-fill {
  fill: #fff;
}
.dark .ql-snow.ql-toolbar button .ql-stroke,
.dark .ql-snow .ql-toolbar button .ql-stroke,
.dark .ql-snow.ql-toolbar button .ql-stroke,
.dark .ql-snow .ql-toolbar button .ql-stroke,
.dark .ql-snow.ql-toolbar button .ql-stroke,
.dark .ql-snow .ql-toolbar button .ql-stroke,
.dark .ql-snow.ql-toolbar .ql-picker-label .ql-stroke,
.dark .ql-snow .ql-toolbar .ql-picker-label .ql-stroke,
.dark .ql-snow.ql-toolbar .ql-picker-label .ql-stroke,
.dark .ql-snow .ql-toolbar .ql-picker-label .ql-stroke,
.dark .ql-snow.ql-toolbar .ql-picker-item .ql-stroke,
.dark .ql-snow .ql-toolbar .ql-picker-item .ql-stroke,
.dark .ql-snow.ql-toolbar .ql-picker-item .ql-stroke,
.dark .ql-snow .ql-toolbar .ql-picker-item .ql-stroke,
.dark .ql-snow.ql-toolbar button .ql-stroke-miter,
.dark .ql-snow .ql-toolbar button .ql-stroke-miter,
.dark .ql-snow.ql-toolbar button .ql-stroke-miter,
.dark .ql-snow .ql-toolbar button .ql-stroke-miter,
.dark .ql-snow.ql-toolbar button .ql-stroke-miter,
.dark .ql-snow .ql-toolbar button .ql-stroke-miter,
.dark .ql-snow.ql-toolbar .ql-picker-label .ql-stroke-miter,
.dark .ql-snow .ql-toolbar .ql-picker-label .ql-stroke-miter,
.dark .ql-snow.ql-toolbar .ql-picker-label .ql-stroke-miter,
.dark .ql-snow .ql-toolbar .ql-picker-label .ql-stroke-miter,
.dark .ql-snow.ql-toolbar .ql-picker-item .ql-stroke-miter,
.dark .ql-snow .ql-toolbar .ql-picker-item .ql-stroke-miter,
.dark .ql-snow.ql-toolbar .ql-picker-item .ql-stroke-miter,
.dark .ql-snow .ql-toolbar .ql-picker-item .ql-stroke-miter {
  stroke: #fff;
}
.dark .ql-snow.ql-toolbar button:hover,
.dark .ql-snow .ql-toolbar button:hover,
.dark .ql-snow.ql-toolbar button:focus,
.dark .ql-snow .ql-toolbar button:focus,
.dark .ql-snow.ql-toolbar button.ql-active,
.dark .ql-snow .ql-toolbar button.ql-active,
.dark .ql-snow.ql-toolbar .ql-picker-label:hover,
.dark .ql-snow .ql-toolbar .ql-picker-label:hover,
.dark .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.dark .ql-snow .ql-toolbar .ql-picker-label.ql-active,
.dark .ql-snow.ql-toolbar .ql-picker-item:hover,
.dark .ql-snow .ql-toolbar .ql-picker-item:hover,
.dark .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.dark .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: #06c;
}
.dark .ql-snow.ql-toolbar button:hover .ql-fill,
.dark .ql-snow .ql-toolbar button:hover .ql-fill,
.dark .ql-snow.ql-toolbar button:focus .ql-fill,
.dark .ql-snow .ql-toolbar button:focus .ql-fill,
.dark .ql-snow.ql-toolbar button.ql-active .ql-fill,
.dark .ql-snow .ql-toolbar button.ql-active .ql-fill,
.dark .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.dark .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.dark .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.dark .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.dark .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.dark .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.dark .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.dark .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.dark .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.dark .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.dark .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.dark .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.dark .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.dark .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.dark .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.dark .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.dark .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.dark .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.dark .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.dark .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.dark .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.dark .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: #06c;
}
.dark .ql-snow.ql-toolbar button:hover .ql-stroke,
.dark .ql-snow .ql-toolbar button:hover .ql-stroke,
.dark .ql-snow.ql-toolbar button:focus .ql-stroke,
.dark .ql-snow .ql-toolbar button:focus .ql-stroke,
.dark .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.dark .ql-snow .ql-toolbar button.ql-active .ql-stroke,
.dark .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.dark .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.dark .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.dark .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.dark .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.dark .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.dark .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.dark .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.dark .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.dark .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.dark .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.dark .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.dark .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.dark .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.dark .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.dark .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.dark .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.dark .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.dark .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.dark .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.dark .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.dark .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: #06c;
}

.dark .ql-snow .ql-picker-options {
  background-color: rgb(57, 70, 92);
  display: none;
  min-width: 100%;
  padding: 4px 8px;
  position: absolute;
  white-space: nowrap;
}
